.aboutUsHead{
    display: flex;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;

}
.FixNow{
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 600;
    line-height: 1.05;
    letter-spacing: .2px;
    letter-spacing: .0125rem;
    color: #0053ff;
    margin-bottom: 1.375rem;
}
.serviceCenter{
    font-size: .9375rem;
    font-weight: 400;
    line-height: 2.27;
    letter-spacing: .14px;
    letter-spacing: .00875rem;
}